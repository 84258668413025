<template>
  <div class="SchoolCenter">
    <!-- {{$i18n.message.项目}} -->
    <div
      id="SchoolCenter"
      style="min-height:800px;width: 100%"
      v-show="!serachBool"
    ></div>
    <div
      id="container"
      style="min-height:800px;width: 100%"
      v-show="serachBool"
    ></div>
    <div class="left-container" ref="totalHeight">
      <transition name="show">
        <div
          class="left-main"
          v-show="leftShow"
          :style="{ 'overflow-y': 'auto', height: contentHeight + 'px' }"
        >
          <div class="content" style="padding: 20px 0px;margin:0px 15px;">
            <a-form>
              <a-form-item v-bind="formItemLayout" label="选择中心">
                <a-tree-select
                  style="width: 100%"
                  :treeData="treeDataSchool"
                  treeNodeFilterProp="title"
                  :value="schoolVal"
                  @change="onChangeSchool"
                  treeCheckable
                  :showCheckedStrategy="SHOW_PARENT"
                  placeholder="请选择中心"
                />
              </a-form-item>
              <a-form-item v-bind="formItemLayout" label="分类">
                <a-select
                  style="width: 100%"
                  v-model="type_val"
                  @change="meList"
                  placeholder="请选择"
                >
                  <a-select-option value="0">筹备中</a-select-option>
                  <a-select-option value="1">已开业</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item v-bind="formItemLayout" label="地理位置">
                <!-- 请输入要转换的地理位置 -->
                <a-input-search
                  placeholder="请输入客户的位置"
                  enter-button
                  @search="transXYFn"
                />
              </a-form-item>
            </a-form>
            <!--                       <div class="row" style="text-align: right;display: block">
                                <a-button type="primary">开始查询</a-button>
                            </div> -->

            <div class="row">
              <span class="list-title">机构列表({{ listArr.length }})</span>
            </div>

            <div class="list-content">
              <ul>
                <li
                  v-for="(item, k) in listArr"
                  :key="k"
                  @click="schoolDetailsFn(item.Id)"
                >
                  <div
                    :class="{
                      line: true,
                      'ant-btn-primary': item.OrgName.indexOf('筹备中') < 0,
                      'line-gray': item.OrgName.indexOf('筹备中') > 0
                    }"
                  ></div>
                  <div class="list-name">
                    <span
                      >{{
                        item.OrgName.indexOf('筹备中') > 0
                          ? item.OrgName.substr(
                              0,
                              item.OrgName.indexOf('筹备中') - 1
                            )
                          : item.OrgName
                      }}
                      <span
                        :class="{
                          'ant-btn-primary': item.OrgName.indexOf('筹备中') < 0,
                          'line-gray': item.OrgName.indexOf('筹备中') > 0
                        }"
                        style="padding:3px 10px 2px;font-size:10px;border-radius:16px;color:#fff;font-size:10px; vertical-align: text-bottom;"
                        >{{
                          item.OrgName.indexOf('筹备中') > 0
                            ? '筹备中'
                            : '已开业'
                        }}</span
                      ></span
                    >
                    <span
                      v-show="item.unfilled_number != 0"
                      style="border-radius:50%; font-size:10px;color:#fff;background:#F5222D;min-width:18px;text-align:center;"
                      >-{{ item.unfilled_number }}</span
                    >
                  </div>
                  <div class="site">地址：{{ item.Addr }}</div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="add-btn ant-btn-primary post_map" @click="onClose"><a-icon type="plus" /></div> -->
        </div>
      </transition>
      <div
        class="cut-btn"
        @click="_cutShow"
        @mouseover="_hintShow"
        @mouseout="_hintShow"
      >
        <a-icon
          :type="leftShow ? 'caret-left' : 'caret-right'"
          style="color:#8A8A8A"
        />
        <div class="hint-msg" v-show="hintMsg">
          <div class="san"></div>
          <span>{{ leftShow ? '收起侧边面板' : '展开侧边面板' }}</span>
        </div>
      </div>
    </div>

    <div v-show="spinning" style="position:fixed;left:50%;top: 0px;">
      <a-spin style="margin: 150px 0%;">
        <a-icon
          slot="indicator"
          type="loading"
          :spinning="spinning"
          style="font-size: 44px;color:red;"
          spin
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import moment from 'moment'
import BMap from 'BMap'
const userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
import { async } from 'q'
function changeTime (time) {
  let date1 = new Date(time)
  let birthday =
    date1.getFullYear() +
    '-' +
    (date1.getMonth() + 1 < 10
      ? '0' + (date1.getMonth() + 1)
      : date1.getMonth() + 1) +
    '-' +
    (date1.getDate() < 10 ? '0' + date1.getDate() : date1.getDate())
  return birthday
}
function formatNumber (value) {
  value += ''
  const list = value.split('.')
  const prefix = list[0].charAt(0) === '-' ? '-' : ''
  let num = prefix ? list[0].slice(1) : list[0]
  let result = ''
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
}
function imgObjFn (str) {
  let objArr = []
  if (!str) {
    return objArr
  }
  let arr = str.split(',')
  for (let i = 0; i < arr.length; i++) {
    let obj = {
      lastModified: 1575944562033,
      lastModifiedDate: '',
      name: '2019-12-10-102232.png',
      originFileObj: 'File',
      percent: 0,
      size: 9020,
      thumbUrl: '',
      type: 'image/png',
      uid: 'vc-upload-1576225313806-2',
      url: arr[i]
    }
    objArr.push(obj)
  }
  return objArr
}
var type_Arr = [] //监听分类帅选条件
export default {
  name: 'SchoolCenter',
  data () {
    return {
      spinning: false,
      formItemLayout: {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 }
      },
      leftShow: true,

      SHOW_PARENT,
      listArr: [], //中心列表
      type_val: undefined,
      id: '', //中心id
      serachBool: false
    }
  },
  created () {
    let that = this
    that.$axios.get(8008, {}, res => {
      console.log(res)
      if (res.data.code == 1) {
        that.treeDataSchool = res.data.data
      } else {
        that.$message.errpr(res.data.msg)
      }
    })
  },
  mounted () {
    this.contentHeight = this.$refs.totalHeight.clientHeight
    console.log(this.contentHeight)
    this.listAjaxFn()
  },
  methods: {
    moment,
    formatNumber,
    //转换经纬度
    transXYFn (address) {
      let that = this
      $.ajax({
        url:
          'https://api.map.baidu.com/geocoder/v2/?output=json&ak=GifbRye3VIFOU6L3WVh61ZYf&address=' +
          address,
        type: 'GET',
        dataType: 'jsonp',
        contentType: 'application/json;charset=utf-8',
        success: function (res) {
          console.log(res)
          that.serachAddress(res.result.location)
        }
      })
      that.serachBool = true
    },
    serachAddress (obj) {
      console.log(obj, 111111, obj.lng, obj.lat)
      let that = this
      let map = new BMap.Map('container')
      let point = new BMap.Point(obj.lng, obj.lat)
      map.centerAndZoom(point, 10)
      let local = new BMap.LocalSearch(map, {
        renderOptions: { map: map, autoViewport: true }
      })
      local.search('七田真', '七田真国际教育', '七田真早期教育')
      //自定义标注
      let myIcon = new BMap.Icon(
        'https://smart-resource.sikegroup.com/9bdb113cad5fcee50bae655cb7adbf2b.png',
        new BMap.Size(50, 50)
      )
      // 创建标注对象并添加到地图
      let marker = new BMap.Marker(point, { icon: myIcon })
      map.addOverlay(marker)
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
      // $.ajax({
      //     url:'https://api.map.baidu.com/place/v2/search?query=肯德基&radius=2000&output=json&ak=GifbRye3VIFOU6L3WVh61ZYf&location='+obj.lng+','+obj.lng,
      //     type:'GET',
      //     dataType: 'jsonp',
      //     contentType:'application/json;charset=utf-8',
      //     success:function(res){
      //         console.log(res);
      //     }
      // })
    },

    //选中心
    onChangeSchool (value) {
      // console.log(value)
      this.schoolVal = value
      this.serachBool = false
      this.listAjaxFn(value.join(','))
    },
    _hintShow () {
      this.hintMsg = !this.hintMsg
    },
    _cutShow () {
      this.leftShow = !this.leftShow
      this.hintMsg = false
    },
    onClose () {
      // this.orgName = '添加中心';
      this.visible = !this.visible
    },
    //地图
    createMap () {
      let that = this
      let map = new BMap.Map('SchoolCenter')
      let mapPoints = that.listArr
      map.clearOverlays() //清空地图上标注点
      let a = mapPoints[0].MapPoint
        ? mapPoints[0].MapPoint.split(',')[0]
        : 113.936979
      let b = mapPoints[0].MapPoint
        ? mapPoints[0].MapPoint.split(',')[1]
        : 22.524739
      let lng = a > 100 ? a : b
      let lat = b > 100 ? a : b

      let num = 0
      map.centerAndZoom(new BMap.Point(lng, lat), 8)
      for (let i = 0; i < mapPoints.length; i++) {
        if (mapPoints[i].MapPoint) {
          num++
          let size = new BMap.Size(36, 36)
          let myIcon = new BMap.Icon(
            mapPoints[i].PartnerType == 2
              ? 'https://smart-resource.sikegroup.com/saas/image/mapIcon2.png'
              : 'https://smart-resource.sikegroup.com/saas/image/mapIcon.png',
            size
          )
          let jina = mapPoints[i].MapPoint.split(',')[0]
          let weib = mapPoints[i].MapPoint.split(',')[1]
          let jin = jina > 100 ? jina : weib
          let wei = weib > 100 ? jina : weib
          let marker = new BMap.Marker(new BMap.Point(jin, wei), {
            icon: myIcon
          }) // 创建标注
          let content = `<div>
                                            <div>${mapPoints[i].OrgName}</div>
                                            <div style="font-size:12px;margin-top:10px;">电话号码: ${
                                              mapPoints[i].Phone
                                            }</div>
                                            <div style="font-size:12px;">地址:  ${mapPoints[
                                              i
                                            ].Addr.slice(
                                              0,
                                              18
                                            )} <br/> <span style="margin-left:30px;">${mapPoints[
            i
          ].Addr.substring(18)}</span></div>
                                       </div>
                                        `
          let opts = {
            maxWidth: 220
          }
          let infoWindow = new BMap.InfoWindow(content, opts)
          marker.addEventListener('mouseover', function (e) {
            let p = e.target
            let scaleVal = map.getZoom()
            let point = new BMap.Point(
              p.getPosition().lng,
              p.getPosition().lat + 4.8 / Math.pow(2, scaleVal - 3)
            )
            // 创建信息窗口对象
            map.openInfoWindow(infoWindow, point)
          })
          marker.addEventListener('mouseout', function () {
            map.closeInfoWindow(infoWindow)
          })
          marker.addEventListener('click', function (e) {
            that.id = mapPoints[i].Id
            that.schoolDetailsFn(mapPoints[i].Id)
          })
          map.addOverlay(marker) // 将标注添加到地图中
        }
      }
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
      console.log(num)
    },

    //list列表
    listAjaxFn (id) {
      let num = 0
      if (id) {
        num++
      }
      let that = this
      that.spinning = true
      let obj = {}
      if (id) {
        obj = { Id: id }
      }
      type_Arr = []
      that.listArr = []
      that.$axios.get(9957, obj, res => {
        that.spinning = false
        if (res.data.code == 1) {
          that.listArr = res.data.data
          type_Arr = res.data.data
          //    console.log(type_Arr,that.listArr)
          //    if(num>0){ that.meList();}else{
          that.createMap()
          //    }
        } else {
          that.$message.error(res.data.msg)
        }
      })
    },
    //分类筛选

    meList () {
      let ing = [] //筹备
      let that = this
      that.serachBool = false
      let start = [] //开业
      let arr = type_Arr
      if (that.type_val && type_Arr.length > 0) {
        that.listArr = []
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].OrgName.indexOf('筹备') > 1) {
            ing.push(arr[i])
          } else {
            start.push(arr[i])
          }
        }
        that.listArr = that.type_val == 1 ? start : ing
        // console.log(that.listArr)
      }
      that.createMap()
    }
  }
}
</script>

<style scoped lang="less">
.SchoolCenter {
  position: relative;
  .left-container {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    height: 95%;
    background: #fff;
  }
  .left-main {
    max-width: 350px;
    width: 340px;
    height: 100%;
    border-radius: 2px;
    position: relative;
  }
  .add-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
  }
  .cut-btn {
    position: absolute;
    top: 10px;
    right: -20px;
    width: 20px;
    height: 40px;
    line-height: 38px;
    background: #fff;
    border: 1px solid #e4e5e6;
    cursor: pointer;
    .hint-msg {
      position: absolute;
      top: 50%;
      right: -85px;
      transform: translateY(-50%);
      background: #000;
      opacity: 0.7;
      padding: 4px 8px;
      white-space: nowrap;
      font-size: 10px;
      line-height: 1.6;
      color: #fff;
      border-radius: 2px;
      .san {
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translateY(-50%);
        border-right: 5px solid #000;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      }
    }
  }
  .list-content {
    padding: 0px 15px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    left: 0px;
    ul {
      li {
        position: relative;
        padding: 8px 16px;
        margin-bottom: 20px;
        .list-name {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          span:first-child {
            font-size: 16px;
            font-weight: 400;
          }
          span:last-child {
            font-size: 14px;
          }
        }
        box-shadow: 0px 2px 8px 0px rgba(117, 113, 249, 0.13);
        .line {
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 100%;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
    .site {
      font-size: 12px;
    }
  }
}
.drawer-content {
  padding-bottom: 40px;
}
.list-title {
  font-size: 18px;
  color: #333333;
}
.drawer-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: left;
  z-index: 1000;
}
.show-enter-active {
  width: 350px;
  opacity: 1;
  transition: all 0.3s ease;
}
.show-leave-active {
  width: 350px;
  opacity: 1;
  transition: all 0.3s ease;
}
.show-enter,
.show-leave-to {
  width: 0;
  opacity: 0;
}
.zIndex {
  background-color: #ffffff;
  z-index: 10;
  padding-left: 12px;
  position: relative;
  margin-bottom: 16px;
}
.ant-form-item {
  margin-bottom: 14px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.post_map {
  position: fixed !important;
  bottom: 100px !important;
  left: 220px;
  z-index: 10;
}
.line-gray {
  background: #bbbcbc;
}
</style>
